import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import Intercom from '@intercom/messenger-js-sdk';
import axios from 'axios';
export default function IntercomMessenger() {
  const { user, isLoaded, isSignedIn } = useUser();
  const [userHash, setUserHash] = useState(null);
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  // Fetch the hash when user is authenticated
  useEffect(() => {
    const getIntercomHash = async () => {
      if (isLoaded && isSignedIn && user) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/v2/user/get-intercom-hash`,
            { userId: user.id },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          setUserHash(response.data.hash);
        } catch (error) {
          console.error('Failed to get Intercom hash:', error);
        }
      }
    };
    getIntercomHash();
  }, [isLoaded, isSignedIn, user]);
  // Initialize Intercom with user data and hash
  useEffect(() => {
    if (isLoaded && isSignedIn && user && userHash) {
      Intercom({
        app_id: intercomAppId,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`.trim(),
        email: user.primaryEmailAddress?.emailAddress,
        created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
        user_hash: userHash, // Include the secure hash
      });
    } else if (isLoaded && !isSignedIn) {
      // For anonymous users
      Intercom({
        app_id: intercomAppId,
      });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [isLoaded, isSignedIn, user, userHash]);
  return null;
}