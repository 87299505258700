import React, { useState, useEffect, useCallback } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Paper,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useClerk } from '@clerk/clerk-react';
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds
const ErrorFallback = ({ error, resetErrorBoundary, isRetrying }) => {
    console.log('Error:', error);
  const isClerkError = error?.toString().includes('ClerkJS');
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 500,
          width: '100%',
          p: 3
        }}
      >
        <Alert
          severity="error"
          icon={<ErrorOutlineIcon />}
          sx={{ mb: 2 }}
        >
          <AlertTitle>
            {isClerkError ? 'Authentication Error' : 'Something went wrong'}
          </AlertTitle>
          {isClerkError ? (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {isRetrying ?
                  'Attempting to restore your session...' :
                  'We\'re having trouble with the authentication service.'}
              </Typography>
              {isRetrying ? (
                <Box display="flex" alignItems="center" gap={2}>
                  <CircularProgress size={20} />
                  <Typography variant="body2">
                    Retrying authentication...
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="body1">Please try:</Typography>
                  <ul>
                    <li>Refreshing the page</li>
                    <li>Signing out and signing back in</li>
                    <li>Clearing your browser cache</li>
                  </ul>
                </>
              )}
            </>
          ) : (
            'An unexpected error occurred. Please try again later.'
          )}
        </Alert>
        {!isRetrying && (
          <Button
            variant="contained"
            color="primary"
            onClick={resetErrorBoundary}
            fullWidth
          >
            Try Again
          </Button>
        )}
      </Paper>
    </Box>
  );
};
const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const clerk = useClerk();
  const handleClerkRetry = useCallback(async () => {
    if (retryCount >= MAX_RETRIES) {
      setIsRetrying(false);
      return;
    }
    setIsRetrying(true);
    try {
      // Attempt to refresh the session
      if (clerk.session) {
        await clerk.session.refresh();
      }
      // If successful, clear the error and reset retry count
      setError(null);
      setRetryCount(0);
      setIsRetrying(false);
    } catch (retryError) {
      console.error('Retry failed:', retryError);
      setRetryCount(prev => prev + 1);
      // Schedule another retry after delay if we haven't hit the max
      if (retryCount < MAX_RETRIES - 1) {
        setTimeout(handleClerkRetry, RETRY_DELAY);
      } else {
        setIsRetrying(false);
      }
    }
  }, [clerk, retryCount]);
  useEffect(() => {
    const handleError = (event) => {
      const error = event.error || event.reason || event;
      console.error('Caught error:', error);
      // Check if it's a Clerk error
      if (error.toString().includes('ClerkJS')) {
        handleClerkRetry();
      }
      setError(error);
    };
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, [handleClerkRetry]);
  const resetErrorBoundary = useCallback(() => {
    setError(null);
    setRetryCount(0);
    handleClerkRetry();
  }, [handleClerkRetry]);
  if (error) {
    return (
      <ErrorFallback
        error={error}
        resetErrorBoundary={resetErrorBoundary}
        isRetrying={isRetrying}
      />
    );
  }
  return children;
};
export default ErrorBoundary;